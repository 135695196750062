    import './scss/main.scss'
    const screenHeight = () => {
        var vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const mq = window.matchMedia('(max-width: 840px)');

    function init(){

    }

    window.onload = (event) => {
        console.log('page is fully loaded');
        init();
        screenHeight();
    };

    window.addEventListener('resize', screenHeight())

    $(document).ready(function(){
        var $slider = $('.hero-slider');

        // Función para mezclar los elementos de un array
        function shuffle(array) {
            var i = array.length,
                j = 0,
                temp;

            while (i--) {
                j = Math.floor(Math.random() * (i+1));

                // Intercambiar elementos con índices aleatorios
                temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }

            return array;
        }

        // Mezcla los elementos de imagen (children) dentro del slider
        var $slides = $slider.children();
        shuffle($slides);

        // Aplica los elementos mezclados al slider
        $slides.appendTo($slider);

        $slider.slick({
            autoplay: true,
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 2000,
            speed: 500,
            cssEase: 'ease-out',
            swipe: true,
        });
    });
